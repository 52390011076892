<template>
    <div
        class="modal fade show"
        id="taggatewayscoverage"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modalTagsHealth" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("main_taggatewayscoverage") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!--begin emptyList component-->
                    <app-emptylist targetObjectName="GATEWAYSHEALTH_LIST" mainTextKey="troubleshooting_emptyTagGatewaysCoverage" subTextKey="troubleshooting_emptyTagGatewaysCoverageSubLabel" imgName="empty_gateway.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                    <!--end emptyList component-->

                    <div v-if="!hasAtLeastOneRelevantRSSIValue && hasAtLeastOneRSSI" class="alert alert-custom alert-outline-danger" role="alert">
                        <div class="alert-icon">
                            <i class="flaticon-warning"></i>
                        </div>
                        <div class="alert-text">{{ $t("troubleshooting_warningInsufRSSI") }}</div>
                        <div class="alert-close">
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">
                                    <i class="ki ki-close"></i>
                                </span>
                            </button>
                        </div>
                    </div>

                    <!--begin: Datatable -->
                    <table id="tagGatewaysCoverage_datatable" class="table table-bordered table-hover" style="margin-top: 10px;text-align: center;"  v-show="!isEmptyList">
                        <thead class="thead-light">
                            <tr>
                                <th>
                                    {{ $t("troubleshooting_gatewayNameLabel") }}
                                </th>
                                <th>
                                    {{ $t("troubleshooting_gatewayLastEmissionLabel") }}
                                </th>
                                <th>
                                    {{ $t("troubleshooting_locationFrameLabel") }}
                                </th>
                                <th v-if="selectedTag && selectedTag.type == 'Tag' && selectedTag.tagType.reference != 'OAL-BT'">
                                    {{ $t("troubleshooting_secureProfileFrameLabel") }}
                                </th>
                                <th>
                                    {{ $t("troubleshooting_rssiLevelLabel") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="gateway in tagGatewaysCoverage" :key="gateway.id">
                                <td style="vertical-align: middle;">{{ gateway.name }}</td>
                                
                                <td style="vertical-align: middle;">
                                    {{ gateway.lastEmission }}
                                </td>
                                
                                <td v-if="gateway.hasOwnProperty('lastKonLocReceived')" style="vertical-align: middle;font-size: 20px;">
                                    <i v-if="!gateway.lastKonLocReceived && !gateway.lastKonSPReceived" class="fas fa-exclamation-circle text-danger"></i>
                                    <i v-else-if="!gateway.lastKonLocReceived && gateway.lastKonSPReceived">-</i>
                                    <i v-else-if="gateway.lastKonLocReceived" class="far fa-check-circle text-success"></i>
                                    <i v-else class="fas fa-exclamation-circle text-danger"></i>
                                </td>
                                <td v-else style="vertical-align: middle;">
                                    N/A
                                </td>

                                <td v-if="gateway.hasOwnProperty('lastKonSPReceived') && selectedTag && selectedTag.type == 'Tag' && selectedTag.tagType.reference != 'OAL-BT'" style="vertical-align: middle;font-size: 20px;">
                                    <i v-if="!gateway.lastKonLocReceived && !gateway.lastKonSPReceived" class="fas fa-exclamation-circle text-danger"></i>
                                    <i v-else-if="gateway.lastKonSPReceived" class="far fa-check-circle text-success"></i>
                                    <i v-else class="fas fa-exclamation-circle text-danger"></i>
                                </td>
                                <td v-else-if="selectedTag && selectedTag.type == 'Tag' && selectedTag.tagType.reference != 'OAL-BT'" style="vertical-align: middle;">
                                    N/A
                                </td>

                                <td v-if="gateway.hasOwnProperty('lastRssiStrength')" style="vertical-align: middle;">
                                    <span v-if="gateway.lastRssiStrength">{{ gateway.lastRssiStrength }}</span>
                                    <span v-else>{{ gateway.lastRssiStrength }}</span>
                                </td>
                                <td v-else style="vertical-align: middle;">
                                    N/A
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                    <!--end: Datatable -->
                </div>
                <div class="modal-footer">
                    <button
                        id="okButton"
                        type="button"
                        class="btn btn-dark"
                        data-dismiss="modal"
                    >{{ $t("common_close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmptyList from "./../utils/emptylist.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            tagGatewaysCoverage: null,
            isEmptyList: false
        };
    },
    props: {
        gateways: {
            type: Array
        },
        selectedTag: {
            type: Object
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        gateways: function(gateways) {
            console.log("Component(taggatewayscoveragemodal)::watch(gateways) called with :", gateways);
            this.tagGatewaysCoverage = [];
            if (gateways.length === 0) {
                this.isEmptyList = true;
            } else {
                this.isEmptyList = false;
                for (var gateway of gateways) {
                    if (gateway.lastEmission != -1) {
                        var lastEmission = moment(gateway.lastEmission).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                        lastEmission += " (" + moment(gateway.lastEmission).fromNow() + ")";
                        gateway.lastEmission = lastEmission;
                    } else {
                        gateway.lastEmission = "-";
                    }
                    this.tagGatewaysCoverage.push(gateway);
                }
            }
        }
    },
    computed: {

        // Computed property used to know if a warning message has to be displayed or not
        hasAtLeastOneRelevantRSSIValue: function() {
            if (this.tagGatewaysCoverage){
                for (var i=0; i<this.tagGatewaysCoverage.length; i++) {
                    if (this.tagGatewaysCoverage[i].hasOwnProperty('lastRssiStrength') && this.tagGatewaysCoverage[i].lastRssiStrength != null && this.tagGatewaysCoverage[i].lastRssiStrength > -65) {
                        return true;
                    }
                }
                return false;
            } else {
                return false;
            }
        },

        hasAtLeastOneRSSI: function() {
            var result = false;
            if (this.tagGatewaysCoverage){
                for (var i=0; i<this.tagGatewaysCoverage.length; i++) {
                    if (this.tagGatewaysCoverage[i].hasOwnProperty('lastRssiStrength') && this.tagGatewaysCoverage[i].lastRssiStrength && this.tagGatewaysCoverage[i].lastRssiStrength != -1) {
                        result = true;
                        break;
                    }
                }
                return result;
            } else {
                return result;
            }
        }

    },
    components: {
        "app-emptylist": EmptyList
    }
};
</script>